import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

const requestURI = 'schedule-management';

export const fetchSchedulingDropdownsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/data/dropdown-list`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchRandomMatchDropdownsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/random-match-dropdowns`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateScheduleSummaryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/generate-schedule-summary`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const generateUserScheduleSummaryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/generate-staff-schedule-summary`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateWorkerScheduleRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/generate-worker-schedule`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateEVVIssuesRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/generate-evv-issues`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const refuseStaffRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/refuse-staff`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const selectStaffRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/select-staff`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const matchAllScheduleRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/match-all`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchMatchSchedulesRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/match-schedules`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateRandomMatchRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/generate-random-match`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateClientScheduleRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/generate-client-schedule`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const assignScheduleRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/assign-schedule`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchSystemModelsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/fetch-models`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getSchedulingMatchFormFieldsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/match-form`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getMatchListRequest = async (payload) => {
    try {
        
        return await axios.post(`${baseAPI}/${requestURI}/generate-match`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

/**
 * 
 * MATCH FORM FIELDS ENDPOINTS
 * 
 */
const settingsURI = requestURI + '/settings';

export const saveSchedulingMatchFormFieldRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${settingsURI}/match-form-fields`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchSchedulingMatchFormFieldsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${settingsURI}/match-form-fields`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}