// form module 
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';



export default
    [
        {
            path: '/dynamic-forms',
            name: 'dynamic_forms',
            component: () => import ('@/views/formBuilder/ViewForms.vue'),
            meta: {
                requiresAuth: true,
                check: 'show_forms',
                title: 'Forms',
                },
            beforeEnter: checkPermissions,
        }, 
        {
            path: '/report-settings',
            name: 'report_settings',
            component: () => import('@/views/report_setup/ReportSettings.vue'),
        },

        {
            path: '/report-forms',
            name: 'report_forms',
            component: () => import('@/views/report_setup/index.vue'),
        },
        {
            path: '/report-setup/settings/categories',
            name: 'ReportCategories',
            component: () => import('@/views/report_setup/categories'),
            meta: { requiresAuth: true }
        },
        {
            path: '/report-setup/settings/report-types',
            name: 'ReportTypes',
            component: () => import('@/views/report_setup/report-types'),
            meta: { requiresAuth: true }
        },
        {
            path: '/report-setup/settings/types',
            name: 'ReportProgramTypes',
            component: () => import('@/views/report_setup/program-types'),
            meta: { requiresAuth: true }
        },
        {
            path: '/report-setup/settings/service-types',
            name: 'ReportServiceTypes',
            component: () => import('@/views/report_setup/service-types'),
            meta: { requiresAuth: true }
        },
        {
            path: '/report-setup/settings/activity-types',
            name: 'ReportActivityTypes',
            component: () => import('@/views/report_setup/activity-types'),
            meta: { requiresAuth: true }
        },
        {
            path: '/report-setup/settings/activity-reason',
            name: 'ReportActivityReason',
            component: () => import('@/views/report_setup/reasons'),
            meta: { requiresAuth: true }
        },
        {
            path: '/report-setup/settings/create-form',
            name: 'ReportForm',
            component: () => import('@/views/report_setup/ReportForm.vue'),
            meta: { requiresAuth: true }
        },

        {
            path: '/report-setup/settings/activity-type/:id',
            name: 'ReportReasons',
            component: () => import('@/views/report_setup/activity-types/Details'),
            meta: { requiresAuth: true }
        },
    ];