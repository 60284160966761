<template>

    
<div class="basic-info">
            <div class="profile-header">
                <div class="profile-avatar">
                    <img :src="userDetails.photo_url ? userDetails.photo_url : '/images/female_avatar.png'" alt="Avatar" class="rounded-circle shadow-sm profile-img" />
                </div>
                <div class="profile-details">
                    <div class="column">
                        
                        <h4 class="name font-weight-bold">{{ userDetails.fullName || 'Not Available'}}</h4>
                        <h5 class="role font-weight-bold">{{ userDetails.role || 'Not Available'}}</h5>
                        <p><b>Status:</b> {{ userDetails.status?.name || 'Not Available'}}</p>
                        <p class="years-with-agency"><b>Years w/ Agency:</b> {{ userDetails.yearsEmployed }} years</p>
                        
                    </div>
                    <div class="column">
                        <p><b>Address:</b> {{ userDetails.address }}</p>
                        <p>
                            <b>Language: </b> <span>{{ userDetails.language || 'Not Available' }} </span>                          
                        </p>
                        
                        <p><b>License:</b> {{ userDetails.cert || 'Not Available' }}</p>
                        <p><b><i class="bi bi-phone"></i></b> {{ userDetails.mobileNumber || 'Not Available' }}</p>

                        
                    </div>
                </div>
            </div>
        </div>


</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useUserData } from '@/utils/useUserData';
import { AvatarPhoto } from "@/utils/otherServices/avatar";

const { userDetails, fetchUserData, loading, error } = useUserData();

const { userId } = defineProps({ userId: String | null });

onMounted(() => {
    fetchUserData(userId);
});
</script>

<style scoped>
.side-container {
    display: flex;
}

.user-avatar {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    /* circular image */
    object-fit: cover;
    object-position: center;
    max-width: 140px;
    max-height: 140px;
}

.profile-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.profile-avatar {
    margin-right: 20px;
}

.profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #4caf50; /* Profile border */
}

.profile-details {
    display: flex;
    flex-direction: row;
    gap: 80px; /* Increased space between the columns */
    flex-wrap: wrap;
}

.profile-details .column {
    flex: 1;
    min-width: 255px;
}

.profile-details h2 {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.profile-details p {
    margin: 5px 0;
    color: #555;
}
</style>