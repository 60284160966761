import { createRouter, createWebHistory } from 'vue-router';
import { middleware } from '@/middleware';
import auth from "./auth";
import tasks from "./tasks";
import incidents from './incidents';
import users from './users';
import nurses from './nurses';
import casecoordinators from './case-coordinators';
import intakecoordinators from './intake-coordinators';
import homecareworkers from './homecareworkers';
import settings from "./settings";
import forms from "./forms";
import dashboards from './dashboards';
import complaints from './complaints';
import clients from './clients';
import roles from './roles';
import systemmodules from './systemmodules';
import nurse_assessment from './nurse_assessment';
import adminstaff from './adminstaff';
import otherstaff from './otherstaff';
import management_module from './management_modules';
import { userPermissions } from "@/middleware/routesGate";
import systemactivities from "./system";
import tenant from "./multitenant";

import schedule_management from './schedule_management';
import report_settings from './report_settings';

// Your routes array
const routes = [
    auth,
    {
        path: '/',
        name: 'MainDashboard',
        component: () =>  import('@/layouts/dashboard/index.vue'),
        meta: { requiresAuth: true },
        //component: () =>  import('@/layouts/landing/index.vue'),
        children: [
            ...tasks,
            ...incidents,
            ...users,
            ...nurses,
            ...casecoordinators,
            ...intakecoordinators,
            ...homecareworkers,
            ...complaints,
            ...forms,
            ...dashboards,
            ...settings,
            ...clients, 
            ...roles,
            ...systemmodules,
            ...nurse_assessment,
            ...adminstaff,
            ...otherstaff,
            ...management_module,
            ...systemactivities,
            ...schedule_management,
            ...tenant,
            ...report_settings
        ]
    },
    {
        path: '/not-authorized',
        name: 'NotAuthorized',
        component: () => import('@/layouts/not-authorized/index.vue'),
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});


middleware(router);

export default router;
