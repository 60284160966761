import { ref, computed } from 'vue';
import { useStore } from "vuex";

export function useUserData() {
    const store = useStore();
    const userDetails = ref({
        // ... initial structure
    });
    const loading = ref(false);
    const error = ref(null);

    function processUserData(details) {
        return {
            id: details.id,
            firstName: details.firstname,
            lastName: details.lastname,
            fullName: details.fullname,
            mobileNumber: details.mobile_number,
            email: details.email,
            role: details.role?.name,
            gender: details.gender?.name,
            jobTitle: details.jobtitle,
            age: details.age,
            // details: details.details, 
            status: details.status,
            language: details.language,
            address: details.address,
            notificationCounts: details.notification_counts,
            notifications: details.notifications,
            cert: "",
            photo_url: details.photo_url,
            yearsEmployed: details.yearsEmployed
        };
    }

    async function fetchUserData(userId = null) {
        try {
            loading.value = true;
            if (userId) {

                const details = await store.dispatch("fetchUserDetailsByIdentifier", userId);
                userDetails.value = processUserData(details?.data?.data);
            } else {

                console.log("comes here 2");
                // const details = computed(()=> store.getters.listUserDetails);
                const details = await store.dispatch("fetchUserDetail");
                userDetails.value = processUserData(details.data);
            }

            // userDetails.value = processUserData(details.data); // processUserData is a function to process the raw data

            loading.value = false;
        } catch (err) {
            error.value = err;
            loading.value = false;
        }
    }

    return { userDetails, fetchUserData, loading, error };

}
